
import { defineComponent, reactive } from "vue";
import ReqeustResponse from "@/components/common/Request/RequestResponse.vue";
import { RequestKindTypes } from "@/models/request";

export default defineComponent({
  name: "Second Opinion Response",
  props: {
    id: String,
  },
  setup(props) {
    const state = reactive({
      id: props.id,
    });

    return {
      state,
      RequestKindTypes,
    };
  },
  components: {
    ReqeustResponse,
  },
});
